<template>
  <header class="topHeader">
    <h1 class="projectHeader">
      <el-icon  style="vertical-align: -12%;" :size="24" @click="goBack">
        <ArrowLeft />
      </el-icon>
      {{props.title}}
      <!-- <span>{{props.title}}</span> -->
    </h1>
  </header>
</template>
<script setup>
import { ArrowLeft } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
const props = defineProps({
  title: String
});

const router = useRouter();
const goBack = () => router.go(-1);
</script>
<style lang="scss" scoped>
.projectHeader {
  line-height: 1.5em;
}
</style>
<template>
  <div class="appContainer" style="overflow-y: scroll">
    <Header :title="project.title"></Header>
    <div class="tags pdlr">
      <el-tag size="large" effect="plain" v-for="city in project.city" :key="city">{{ city }}</el-tag>
      <el-tag v-if="project.type" class="mx-1" size="large" effect="plain">{{ project.type }}</el-tag>
    </div>
    <div class="info pdlr commonPadding contentColor">
      <span>{{ project.time }}</span>
      <span>{{ project.author }}</span>
      <span>收录于{{ project.collectedAt }}</span>
    </div>
    <div class="content pdlr">
      <div class="excerpt  commonPadding contentColor">{{ project.excerpt }}</div>
      <div class="coverContainer">
        <el-image style="width: 100%;height: auto;margin-bottom: 20px;" :src="project.img_url"
          alt="project cover image not fount" class="cover">
        </el-image>
        <div class="checkDetail" @click="gotoDetail">查看详情</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Header from "@/components/app/HeaderOfProject.vue";
import { ArrowLeft } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const Store = useStore();
const id = route.params.id;
const project = Store.state.picWallImgList.filter(item => item.id === id)[0];

const gotoDetail = () => router.push(`/app/detail/${id}`);
// const gotoDetail = () => window.location.href = project.h5_url;
</script>
<style scoped lang="scss">
.commonPadding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.excerpt {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  text-align: justify;
}

.content {
  flex: 1;
  overflow-y: scroll;
}

.coverContainer {
  position: relative;
}

.cover {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.checkDetail {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 92px;
  // padding: 18px 48px;
  // border:0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  // letter-spacing: 1.5px;
  // font-style: normal;
  background: #0A9DAA;
}

.el-tag {
  margin-right: 10px;
  margin-bottom: 6px;
  border: none;
  color: #02bdcd;
  background: rgba(2, 189, 205, 0.12);
  font-size: 16px;
}

.info {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  text-align: justify;

  span {
    margin-right: 25px;
  }
}

.contentColor {
  color: rgba(255, 255, 255, 0.6);
}
</style>